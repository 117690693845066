<template>
    <section class="section">
      <div class="container">
        <div class="columns">
          <div class="column">
            <div class="box">
                <b-menu>
                  <b-menu-list label="Mis datos">
                  <b-menu-item icon="id-badge"  label="Información" tag="router-link" :to="{name: 'perfilInformacion'}"/>
                  <b-menu-item tag="router-link" :to="{name: 'perfilCertificado'}">
                   <template #label>
                     <i class="fas fa-stamp"></i>&nbsp;&nbsp;Certificado <span v-if="userHasCertificate && (userCertificateIsAboutToExpired || userCertificateIsExpired)" class="">&nbsp;<i class="fas fa-exclamation-triangle fa-fw" :class="{'has-text-warning': userCertificateIsAboutToExpired, 'has-text-danger animated flash': userCertificateIsExpired}"></i></span>
                   </template>
                  </b-menu-item>
                  </b-menu-list>
                  <b-menu-list label="Mi cuenta">
                  <b-menu-item icon="key" label="Cambiar clave" tag="router-link" :to="{name: 'perfilClave'}"/>
                  <b-menu-item icon="signature" label="Elegir firmador" tag="router-link" :to="{name: 'perfilFirmador'}"/>
                  </b-menu-list>
                  <b-menu-list label="Soporte">
                  <b-menu-item icon="external-link-alt" label="Centro de Ayuda Lyris IT" tag="a" target="_blank" href="https://soporte-lyris.atlassian.net/servicedesk/customer/portals"  />
                  <b-menu-item icon="external-link-alt" label="Documentación Signar" tag="a" target="_blank" href="https://docs.signar.com.ar"  />
                  </b-menu-list>
                  <b-menu-list label="Acciones">
                  <b-menu-item icon="sign-out-alt" label="Cerrar sesión" tag="router-link" :to="{name: 'logout'}"  />
                  </b-menu-list>
                </b-menu>
            </div>
          </div>
          <div class="column is-9">
                <router-view @update="updateUser"></router-view>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
import userCertificateExpiration from '@/mixins/userCertificateExpiration'

export default {
  mixins: [userCertificateExpiration],
  methods: {
    updateUser: function () {
      this.$store.dispatch('profile/fetchUser', this.userSession.id)
        .then(response => {
          const userAux = {
            id: response.data.id,
            name: response.data.nombre,
            surname: response.data.apellido,
            roles: response.data.roles.map(r => r.id),
            areas: response.data.areas.map(a => a.id),
            organization: response.data.organizacion.id,
            firmadores_habilitados: response.data.firmadores_habilitados
          }
          this.$store.commit('setUser', userAux)
        })
        .catch((error) => {
          console.error(error)
          this.$buefy.snackbar.open({
            message: 'Error al actualizar la sesión de usuario',
            type: 'is-danger'
          })
        })
    }
  },
  computed: {
    userSession: function () {
      return this.$store.getters.getUser
    }
  }
}
</script>
