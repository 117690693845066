<template>
  <section>
    <section class="box">

    <i class="fas fa-check-circle fa-lg has-text-success is-pulled-right" v-if="ok"></i>
    <h1 class="subtitle is-5">
      Administrar usuario
    </h1>
    <h1 class="title is-2">Certificado</h1>
    <div class="content">
      <p>Tiene la posibilidad de cargar un archivo .p12 para el usuario. En el caso que ya tenga una copia cargada, eliminará la copia actual almacenada.</p>
      <p>Opcionalmente puede cargar la fecha de vencimiento del certificado. Signar le notificará visualmente al usuario cuando se encuentre a un mes de su expiración</p>
      <p>Como <b>administrador</b> de la organización, puede eliminar el certificado del usuario.</p>
    </div>
    <hr>
    <div class="notification is-warning" v-if="error">{{error}}</div>
    <div class="columns">
      <div class="column">
        <h1 class="subtitle is-5" >{{withCertificate ? 'Reemplazar' : 'Subir nuevo'}} certificado</h1>
        <b-field>
            <b-field class="file is-primary" :class="{'has-name': !!file}">
                <b-upload v-model="file" class="file-label" rounded accept="application/x-pkcs12" @invalid="invalid">
                    <span class="file-cta">
                        <b-icon class="file-icon" icon="search"></b-icon>
                        <span class="file-label">Examinar</span>
                    </span>
                    <span class="file-name" v-if="file">
                        {{ file.name }}
                    </span>
                </b-upload>
            </b-field>
        </b-field>
      </div>
      <div class="column">
        <h1 class="subtitle is-5">Fecha de vencimiento <span class="is-size-7">(opcional)</span></h1>
         <b-field>
            <b-datepicker
                v-model="expireDateInput"
                locale="es-AR"
                placeholder="Haga clic aquí"
                icon="calendar"
                trap-focus>
            </b-datepicker>
        </b-field>
      </div>
    </div>
    <hr>
      <div class="buttons is-right">
        <button
          class="button is-primary"
          :disabled="!file"
          @click="submit"
        >Guardar</button>
      </div>
    </section>
    <section v-if="withCertificate">
      <div class="notification is-success mb-0 is-radiusless" v-if="!isAboutToExpired && !isExpired">
        <i class="fas fa-check-circle"></i>&nbsp;&nbsp;La copia de seguridad de certificado se encuentra disponible
      </div>
      <div class="notification is-warning mb-0 is-radiusless" v-else-if="isAboutToExpired && !isExpired">
        <i class="fas fa-exclamation-triangle"></i>&nbsp;&nbsp;La copia de seguridad de certificado expira {{expireDate| moment("from", "now")}}
      </div>
      <div class="notification is-danger mb-0 is-radiusless" v-else-if="!isAboutToExpired && isExpired">
        <i class="fas fa-times-circle"></i>&nbsp;&nbsp;La copia de seguridad de certificado se encuentra expirada. Venció el {{expireDate| moment("L")}}, {{expireDate| moment("from", "now")}}
      </div>
      <div class="box mt-0">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
         <p v-if="!expireDate"><span class="has-text-primary"><i class="fas fa-exclamation-triangle"></i>&nbsp;Atención:</span> No ha ingresado una fecha de vencimiento. Usted puede ingresar la fecha de vencimiento de su certificado y Signar le notificará a un mes de su expiración</p>
         <p v-else><span class="has-text-primary"><i class="far fa-calendar"></i> Fecha de vencimiento</span> <span class="is-capitalized">{{expireDate| moment("L")}} - {{expireDate| moment("from", "now")}}</span></p>
        <div class="buttons is-right ml-3" >
              <a
                class="button is-small is-danger is-outlined"
                @click="openDeleteCert"
              >
                <i class="fas fa-trash"></i>&nbsp;Eliminar
              </a>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import ModalEliminarCertificado from '@/components/organizacion/usuario/ModalEliminarCertificado'

export default {
  props: ['idOrganization', 'idUser'],
  data () {
    return {
      ok: false,
      file: null,
      error: null,
      today: new Date(),
      expireDate: null,
      expireDateInput: null
    }
  },
  beforeMount () {
    if (this.user.expiracion_keystore) this.expireDate = new Date(this.user.expiracion_keystore)
  },
  methods: {
    invalid () {
      this.$buefy.snackbar.open({
        message: 'El archivo ingresado no es un certificado con extension p12',
        type: 'is-danger'
      })
    },
    submit: function () {
      this.ok = false
      this.error = null
      this.startLoading()
      const data = new FormData()
      data.append('archivo', this.file)
      if (this.expireDateInput) data.append('expiracion', this.$moment(this.expireDateInput).format('YYYY-MM-DD'))
      this.$http
        .request({
          method: 'post',
          url: '/usuario/' + this.idUser + '/keystore',
          data: data
        })
        .then(response => {
          this.ok = true
          this.$buefy.snackbar.open({
            message: 'El certificado se ha guardado con exito',
            duration: 6000
          })
          this.file = null
          this.expireDate = this.expireDateInput
          this.expireDateInput = null
          this.$emit('updateResource')
          setTimeout(() => {
            this.ok = false
          }, 3000)
        })
        .catch(error => {
          this.ok = false
          this.error = error.mensaje
          console.error(error)
          this.$buefy.snackbar.open({
            message: 'Error al subir el certificado',
            type: 'is-warning',
            actionText: 'Cerrar',
            duration: 6000
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    openDeleteCert: function () {
      this.$buefy.modal.open({
        parent: this,
        component: ModalEliminarCertificado,
        width: 600,
        events: {
          deleteCert: () => {
            this.deleteCert()
          }
        }
      })
    },
    deleteCert: function () {
      this.startLoading()
      this.$http
        .delete(`/usuario/${this.idUser}/keystore`)
        .then(res => {
          this.ok = true
          this.$buefy.snackbar.open({
            message: 'El certificado se ha eliminado con exito',
            duration: 6000
          })
          this.$emit('updateResource')
          setTimeout(() => {
            this.ok = false
          }, 3000)
        })
        .catch(error => {
          this.ok = false
          this.error = error.mensaje
          console.error(error)
          this.$buefy.snackbar.open({
            message: 'Error al eliminar el certificado',
            type: 'is-warning',
            actionText: 'Cerrar',
            duration: 6000
          })
        })
        .finally(() => {
          this.stopLoading()
          this.showDeleteModal = false
        })
    }
  },
  computed: {
    user: function () {
      return this.$store.state.organizacion.selectedUser
    },
    nameFile: function () {
      if (!this.file) return
      if (this.file && this.file.name.length > 20) {
        return this.file.name.substring(0, 20) + '...'
      }
      return this.file.name
    },
    withCertificate: function () {
      return this.user.keystore
    },
    expirationWarningDate: function () {
      if (!this.user.expiracion_keystore) return null
      return this.$moment(this.user.expiracion_keystore).subtract(1, 'month').toDate()
    },
    isAboutToExpired: function () {
      if (!this.expirationWarningDate) return null
      return this.expirationWarningDate < this.today && this.today < this.expireDate
    },
    isExpired: function () {
      if (!this.expireDate) return null
      if (!this.expirationWarningDate) return null
      return this.today >= this.expireDate
    }
  }
}
</script>
