import { render, staticRenderFns } from "./ModuleBrief.vue?vue&type=template&id=3d70abf7&scoped=true&"
import script from "./ModuleBrief.vue?vue&type=script&lang=js&"
export * from "./ModuleBrief.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d70abf7",
  null
  
)

export default component.exports