<template>
  <b-sidebar
      class="pila-sidebar"
      type="is-light"
      :fullheight="true"
      :mobile="'fullwidth'"
      :overlay="true"
      :can-cancel="false"
      v-model="showSidebar"
    >
    <div class="has-background-primary-dark sticky-top">
          <div class="first-row p-3">
            <div class="is-pulled-right ml-2 mb-2">
              <a @click="close" class="has-text-white"
                ><i class="fas fa-times fa-lg"></i
              ></a>
            </div>
            <h3 class="subtitle is-7 has-text-white">Pila de documentos - {{docStack.length}} documento{{docStack.length > 1 ? 's' : ''}}</h3>
            <h3 class="title is-6 has-text-white">Documentos a firmar</h3>
          </div>
      </div>
      <section class="section">
        <h3 class="title is-6">Paso 1</h3>
        <h3 class="subtitle is-6">Pila de documentos</h3>
        <div class="card is-radiusless" v-for="doc in docStack" :key="doc.id">
           <header class="card-header">
          <div class="card-header-title is-400 is-flex is-flex-direction-row is-align-items-flex-start">
            <p class="mr-3"><i class="fas fa-fw" :class="doc.tipo_documento_id == 'carpeta' ? 'fa-folder' : doc.tipo_documento_id == 'recibo' ? 'fa-dollar-sign' : 'fa-file'"></i></p>
            <p class="is-flex-grow-1 break-word">{{doc.nombre}}</p>
          </div>
          <div class="card-header-icon" aria-label="more options">
            <button @click="removeFromPila(doc.id)" class="delete is-pulled-right"></button>
          </div>
           </header>
        </div>
        <div class="card is-radiusless">
           <header class="card-header">
             <div class="card-header-title is-400 has-text-centered is-flex is-flex-direction-row is-align-items-flex-start">
             <a @click="cleanPila()" class="is-size-7 is-flex-grow-1">Limpiar fila</a>
             </div>
          <!-- <div class="card-header-title is-400 is-flex is-flex-direction-row is-align-items-flex-start">
            <p class="mr-3"><i class="fas fa-fw" :class="doc.tipo_documento_id == 'carpeta' ? 'fa-folder' : doc.tipo_documento_id == 'recibo' ? 'fa-dollar-sign' : 'fa-file'"></i></p>
            <p class="is-flex-grow-1 break-word">{{doc.nombre}}</p>
          </div>
          <div class="card-header-icon" aria-label="more options">
            <button @click="removeFromPila(doc.id)" class="delete is-pulled-right"></button>
          </div> -->
           </header>
        </div>
        <br>
        <!-- <div class="box" v-for="doc in docStack" :key="doc.id">
          <i class="fas fa-fw" :class="doc.tipo_documento_id == 'carpeta' ? 'fa-folder' : doc.tipo_documento_id == 'recibo' ? 'fa-dollar-sign' : 'fa-file'"></i>
                  {{doc.nombre}}
        </div> -->
        <!-- <br> -->
        <!-- <div class="content">
          <table class="table">
            <tbody>
              <tr v-for="doc in docStack" :key="doc.id" :title="doc.nombre">
                <td class="">
                  <i class="fas fa-fw" :class="doc.tipo_documento_id == 'carpeta' ? 'fa-folder' : doc.tipo_documento_id == 'recibo' ? 'fa-dollar-sign' : 'fa-file'"></i>
                  {{doc.nombre}}
                </td>
                <td>
                  <button @click="removeFromPila(doc.id)" class="delete is-pulled-right"></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div> -->
        <h3 class="title is-6">Paso 2</h3>
        <h3 class="subtitle is-6">Seleccione el firmador a utilizar</h3>
        <div class="box is-flex is-flex-direction-row is-align-items-center" v-if="user.firmadores_habilitados.signar">
          <div class="is-flex-grow-1">
           <b-radio v-model="firmadorSelected"
              native-value="signar">
              <b>Signar® Firmador</b>
          </b-radio>
          </div>
          <img src="/statics/signar-logo.svg" class="image firmador-select" alt>
        </div>
        <div class="box is-flex is-flex-direction-row is-align-items-center" v-if="user.firmadores_habilitados.pfdrar">
          <div class="is-flex-grow-1">
          <b-radio v-model="firmadorSelected"
              disabled
              native-value="pfdrar">
              <p class="has-text-danger is-size-7">No disponible</p>
              <b>Plataforma de Firma Digital Remota - Secretaria de Modernización - Argentina</b>
          </b-radio>
          </div>
          <img src="/statics/pfdrar-logo.png" class="image firmador-select" alt>
        </div>
        <div class="buttons is-centered">
          <button @click="goFirmar()" class="button is-link" :disabled="!firmadorSelected || !docStack.length">Firmar documentos</button>
        </div>
      </section>
    <!-- <a class="is-pulled-right" @click="close">
      <i class="fas fa-arrow-left fa-lg"></i>
    </a>
    <h1 class="title is-size-5">Docs a firmar</h1> -->
  </b-sidebar>
</template>

<script>
import ModalLaunchFirmador from './ModalLaunchFirmador'

export default {
  data () {
    return {
      showSidebar: false,
      firmadorSelected: null
    }
  },
  created: function () {
    this.$eventHub.$on('showPila', this.open)
    this.$eventHub.$on('togglePila', this.toggle)
    this.$eventHub.$on('hidePila', this.close)
  },
  methods: {
    open: function () {
      this.showSidebar = true
      if (this.user.firmadores_habilitados.signar && !this.user.firmadores_habilitados.pfdrar) {
        this.firmadorSelected = 'signar'
      } else if (!this.user.firmadores_habilitados.signar && this.user.firmadores_habilitados.pfdrar) {
        this.firmadorSelected = 'pfdrar'
      } else {
        this.firmadorSelected = null
      }
    },
    toggle: function () {
      this.showSidebar = !this.showSidebar
      if (this.user.firmadores_habilitados.signar && !this.user.firmadores_habilitados.pfdrar) {
        this.firmadorSelected = 'signar'
      } else if (!this.user.firmadores_habilitados.signar && this.user.firmadores_habilitados.pfdrar) {
        this.firmadorSelected = 'pfdrar'
      } else {
        this.firmadorSelected = null
      }
    },
    close: function () {
      this.showSidebar = false
      this.firmadorSelected = null
    },
    removeFromPila: function (id) {
      this.$store.commit('firmador/removePila', id)
    },
    cleanPila: function () {
      this.$store.commit('firmador/cleanPila')
    },
    goFirmar: function () {
      console.log('help')
      const payload = {
        opcion: 'documentos',
        firmador: this.firmadorSelected,
        parametros: {
          documentos: this.docStack.map(doc => doc.id)
        }
      }
      this.$store.dispatch('firmador/unload')
      this.$store.commit('firmador/setPayload', payload)
      this.$store.commit('firmador/setGoBack', { name: this.$route.name, params: this.$route.params })
      this.$buefy.modal.open({
        parent: this,
        canCancel: false,
        hasModalCard: true,
        component: ModalLaunchFirmador
      })
      this.close()
    }
  },
  computed: {
    user: function () {
      return this.$store.getters.getUser
    },
    docStack: function () {
      return this.$store.state.firmador.pila
    },
    usarFirmar: function () {
      return this.$store.getters.getUser.firmadores_habilitados.pfdrar
    }
  },
  watch: {
    docStack: function (newVal, oldVal) {
      if (newVal.length > 0) {
        this.$eventHub.$emit('showPila')
      } else {
        this.$eventHub.$emit('hidePila')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sticky-top{
  position: sticky;
  top: 0px;
  z-index: 10;
}
.firmador-select{
  width: 75px;
  height: auto;
}
// section.section {
//   padding-top: 1em;
// }
// .title {
//   color: #fff;
// }
// table td {
//   word-break: break-all;
// }
// table.table {
//   background-color: transparent;
//   tr td {
//     color: #fff;
//     border: 0;
//   }
//   tr:hover {
//     background-color: #333f4a;
//   }
// }
</style>
