<template>
  <div class="box">
    <h1 class="subtitle is-5">
      Administrar usuario
    </h1>
    <h1 class="title is-2">
      Roles
    </h1>
    <div class="content">
      <p>
      Arrastre el rol para asignarle o quitarle el rol. Una vez que finalizó de cargar los datos, presione el botón <i>Guardar</i>
      </p>
    </div>
    <hr>
    <section>
      <div class="columns">
        <div class="column">
          <b-message type="is-primary">
            <h1 class="title is-5">Roles activos</h1>
            <h1 class="subtitle is-6">Arrastre aquí para activar</h1>
            <draggable v-model="rolesUsr" v-bind="{group:'roles'}" tag="b-taglist" @start="drag=true" @end="drag=false" style="min-height: 25px">
              <b-tag v-for="rolUsr in rolesUsr" :key="rolUsr.id" type="is-primary" size="is-medium">
                {{rolUsr.descripcion}}
              </b-tag>
            </draggable>
          </b-message>
        </div>
        <div class="column">
          <b-message>
            <h1 class="title is-5">Roles disponibles</h1>
            <h1 class="subtitle is-6">Arrastre aquí para desactivar</h1>
            <draggable v-model="rolesOrg" v-bind="{group:'roles'}" tag="b-taglist" @start="drag=true" @end="drag=false" style="min-height: 25px">
              <b-tag v-for="rolOrg in availableRoles" :key="rolOrg.id" type="is-dark" size="is-medium">
                {{rolOrg.descripcion}}
              </b-tag>
            </draggable>
          </b-message>
        </div>
      </div>
       <hr>
      <div class="buttons is-right">

        <button @click.prevent="submit()" class="button is-primary">Guardar</button>
      </div>
    </section>

  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  props: ['idOrganization', 'idUser'],
  components: {
    draggable
  },
  data () {
    return {
      error: '',
      rolesUsr: [],
      rolesOrg: [],
      drag: false,
      showHelp: false
    }
  },
  created () {
    this.rolesUsr = this.usuario.roles
    this.obtenerRolesDisponibles()
  },
  methods: {
    obtenerRolesDisponibles () {
      this.startLoading()
      this.$http
        .get('/organizacion/' + this.idOrganization + '/rol?size=100')
        .then(response => {
          this.rolesOrg = response.data.filter(rol => { return !this.rolesUsr.map(x => { return x.id }).includes(rol.id) })
          this.stopLoading()
        })
        .catch(error => {
          this.$buefy.snackbar.open({
            message: error.mensaje,
            type: 'is-danger'
          })
          this.stopLoading()
        })
    },
    submit () {
      const data = {
        roles: this.rolesUsr.map(x => { return x.id })
      }
      this.$http
        .request({
          method: 'put',
          url: '/usuario/' + this.idUser + '/rol',
          data: data
        })
        .then(response => {
          this.$emit('updateResource')
          this.$buefy.snackbar.open({
            message: 'Roles actualizados exitosamente.',
            type: 'is-success'
          })
          this.stopLoading()
        })
        .catch(error => {
          this.$buefy.snackbar.open({
            message: error.mensaje,
            type: 'is-danger'
          })
          this.stopLoading()
        })
    }
  },
  computed: {
    usuario: function () {
      return this.$store.state.organizacion.selectedUser
    },
    availableRoles: function () {
      return this.rolesOrg.filter(r => !this.rolesUsr.includes(r))
    }
  }
}
</script>

<style lang="scss" scoped>
.tag:hover {
  cursor: move;
}
</style>
