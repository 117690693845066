<template>
  <section class="buscador-container">
    <div class="columns">
      <div class="column is-3" v-show="showFiltros">
        <filtros :query.sync="query"></filtros>
      </div>
      <div class="column">
        <div class="columns is-vcentered is-variable is-1 is-mobile">
          <div class="column">
            <div class="box search-box">
              <i class="fas fa-filter float-filtro clickable" @click="toggleFilters" :class="{'has-text-primary': showFiltros}"></i>
              <input v-model="inputSearch" @keyup="filterDocuments" type="text" placeholder="Escriba el nombre del documento">
            </div>
          </div>
          <div class="column is-narrow">
            <button class="button is-primary search-button is-radiusless" @click="filterDocuments">
              <i class="search-icon" :class="iconsToggleLoading"></i>
            </button>
          </div>
        </div>
        <div class="box my-2" :class="{'is-selected': selectedFile == res.id, 'p-0': settings.showThumbnails, 'py-3 px-4': !settings.showThumbnails}" @click="selectedFile = res.id" v-for="res in searchResult" @dblclick="dobleClick(res)" :key="res.id">
          <div class="media result is-align-items-center">
            <div class="media-left">
              <i :class="getDocumentIcon(res)" class="is-size-4" v-if="!settings.showThumbnails"></i>
              <div v-else>
                <div class="thumbnail center" v-if="res.tipo_documento_id == 'carpeta'" :style="`background-image: url('/statics/default-folder.png')`" />
              <div class="thumbnail" :class="{'center': !res.preview_uri}" v-else :style="`background-image: url(${res.preview_uri ? res.preview_uri : '/statics/default-document.png'})`" />
              </div>
            </div>
            <div class="media-content pr-2" :class="{'': settings.showThumbnails}">
              <h1 class="title is-6">{{res.nombre}}</h1>
              <h2 class="subtitle is-7">{{res.ruta}}</h2>
            </div>
            <div class="media-right px-3">
              <a @click="dobleClick(res)"><i class="fas fa-angle-double-right fa-lg"></i></a>
            </div>
          </div>
        </div>
        <infinite-loading @infinite="infiniteHandler" :identifier="infiniteLoaderIdentifier">
          <div slot="no-more" class="section"><span class="is-mono"><i class="far fa-surprise"></i><br>Fin de los resultados</span></div>
          <div slot="no-results" class="section"><span class="is-mono"><i class="far fa-surprise"></i><br>Fin de los resultados</span></div>
        </infinite-loading>
      </div>
    </div>
  </section>
</template>

<script>
import Filtros from './Filtros'
import InfiniteLoading from 'vue-infinite-loading'
import { debounce } from 'lodash'

export default {
  props: ['active'],
  components: {
    Filtros,
    InfiniteLoading
  },
  data () {
    return {
      showFiltros: false,
      searchResult: [],
      selectedFile: null,
      inputSearch: '',
      isLoading: false,
      infiniteLoaderIdentifier: Math.random(),
      query: {
        confidencial: false,
        tipoDocumento: null,
        origen_id: null,
        metadatos: [],
        serie: null,
        aspectos: [],
        estado: null
      },
      queryParams: {
        page: 1,
        size: 10
      }
    }
  },
  methods: {
    toggleFilters: function () {
      this.showFiltros = !this.showFiltros
    },
    infiniteHandler: function ($state) {
      this.$http
        .post(`/busqueda-documento?${this.urlQuery}`, this.payload)
        .then(({ data }) => {
          this.isLoading = false
          if (data.current_page === data.last_page) {
            this.searchResult.push(...data.data)
            $state.complete()
          }
          if (data.current_page < data.last_page) {
            this.queryParams.page += 1
            this.searchResult.push(...data.data)
            $state.loaded()
          }
        })
    },
    filterDocuments: debounce(function () {
      this.queryParams.page = 1
      this.searchResult = []
      this.infiniteLoaderIdentifier = Math.random()
    }, 500
    ),
    getDocumentIcon: function (documento) {
      switch (documento.tipo_documento_id) {
        case 'carpeta':
          return 'fas fa-folder'
        case 'recibo':
          return 'fas fa-dollar-sign'
        case 'unidadCompuesta':
          return 'far fa-file-archive'
        default:
          return 'far fa-file'
      }
    },
    dobleClick: function (documento) {
      if (documento.tipo_documento_id === 'carpeta') {
        this.$router.push('/repositorio/' + documento.id)
      } else if (documento.tipo_documento_id === 'recibo') {
        this.$router.push('/documento/' + documento.id)
      } else if (documento.tipo_documento_id === 'unidadCompuesta') {
        this.$router.push('/unidad-compuesta/' + documento.id)
      } else {
        this.$router.push('/documento/' + documento.id)
      }
    }
  },
  computed: {
    settings () {
      return this.$store.getters['customSettings/userSettings'].homeSearch
    },
    iconsToggleLoading: function () {
      if (this.isLoading) return 'fas fa-circle-notch fa-spin'
      return 'fas fa-search'
    },
    payload: function () {
      const thePayload = {}
      if (this.inputSearch) { thePayload.nombre = this.inputSearch }
      if (this.query.confidencial) { thePayload.confidencial = this.query.confidencial }
      if (this.query.tipoDocumento) { thePayload.tipo_documento = this.query.tipoDocumento }
      if (this.query.origen_id) { thePayload.origen_id = this.query.origen_id }
      if (this.query.metadatos.length) { thePayload.metadatos = this.query.metadatos }
      if (this.query.serie) { thePayload.serie = this.query.serie.id }
      if (this.query.aspectos.length) { thePayload.aspectos = this.query.aspectos }
      if (this.query.estado) { thePayload.estado = this.query.estado }
      return thePayload
    },
    urlQuery () {
      return Object.keys(this.queryParams)
        .map(i => {
          return i + '=' + this.queryParams[i]
        })
        .join('&')
    }
  }
}
</script>

<style lang="scss" scoped>
.buscador-container{
  padding-bottom: 150px;
}
.float-buscador {
  position: absolute;
  font-size: 1.8em;
  top: 0.8em;
  right: 0.8em;
}
.float-filtro {
  position: absolute;
  font-size: 1.8em;
  top: 0.8em;
}
.search-button {
  padding: 1.25em;
  height: auto;
}
.search-icon {
  font-size: 1.8em;
}
.clickable {
  cursor: pointer;
}
input {
  width: 100%;
  font-size: 1.8em;
  border: 0;
  padding-left: 1.6em;
  padding-right: 1.6em;
  font-weight: 300;
}
.search-box {
  position: relative;
}
.is-selected{
  background-color: $primary;
  color: #FFF;
  .title, .subtitle{
    color: #FFF;
  }
  a{
     color: #FFF;
  }
}
.thumbnail{
  height: 50px;
  width: 150px;
  background-size: cover;
  background-position: top center;
  &.center{
    background-position: center center;
  }
}
.result .media-content{
  .title, .subtitle {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    user-select: none;
  }
}
</style>
