<template>
  <section>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true">
      <b-icon pack="fas" icon="sync-alt" size="is-large" custom-class="fa-spin"></b-icon>
    </b-loading>

    <div class="box pb-0">
      <h1 class="subtitle is-4">Mis certificados</h1>
      <h1 class="title is-3">Nueva solicitud</h1>
      <b-steps v-model="step" :animated="true" :has-navigation="false">
        <b-step-item :clickable="false" step="1" label="Inicio"></b-step-item>
        <b-step-item :clickable="false" step="2" label="Clave de firma"></b-step-item>
        <b-step-item :clickable="false" :visible="!delegateKey" step="3" label="Descarga de clave"></b-step-item>
        <b-step-item :clickable="false" :step="!delegateKey ? '4' : '3'" label="Fin"></b-step-item>
      </b-steps>
    </div>

    <div class="box" v-if="step === 0">
      <h1 class="subtitle is-5">Inicio</h1>
      {{ instrucciones }}
      <div class="buttons is-right mt-6">
        <button @click="nextStep" class="button is-primary">
          Continuar&nbsp;&nbsp;
          <i class="fas fa-arrow-right fa-sm"></i>
        </button>
      </div>
    </div>

    <div class="box" v-else-if="step === 1">
      <h1 class="subtitle is-5">Clave de firma</h1>
      <p class="mt-4 mb-3">Verifique sus datos de usuario. Si observa algún error, comuníquese con el administrador antes de continuar con la solicitud.</p>
      <table class="table is-bordered">
        <tbody>
          <tr>
            <th>Nombre</th>
            <td>{{usuario.nombre}}</td>
          </tr>
          <tr>
            <th>Apellido</th>
            <td>{{usuario.apellido}}</td>
          </tr>
          <tr>
            <th>CUIL</th>
            <td>{{usuario.cuil}}</td>
          </tr>
          <tr>
            <th>Email</th>
            <td>{{usuario.email}}</td>
          </tr>
        </tbody>
      </table>
      <p class="mt-4 mb-2">Ingrese una contraseña para proteger su clave.</p>
      <div class="field">
        <label class="label">Contraseña</label>
        <div class="control">
          <input class="input" type="password" name="password" required maxlength="64" v-model="password"
            v-validate="'required|min:4|max:64'" ref="password" data-vv-scope="passwords" data-vv-as="Contraseña">
          <span class="help is-danger" v-if="errors.has('password', 'passwords')">
            {{ errors.first('password', 'passwords') }}
          </span>
        </div>
      </div>
      <div class="field">
        <label class="label">Confirmar contraseña</label>
        <div class="control">
          <input class="input" type="password" name="confirmedPassword" required maxlength="64" v-model="confirmedPassword"
            v-validate="'required|confirmed:password'" data-vv-scope="passwords" data-vv-as="Confirmar contraseña">
          <span class="help is-danger" v-if="errors.has('confirmedPassword', 'passwords')">
            {{ errors.first('confirmedPassword', 'passwords') }}
          </span>
        </div>
      </div>
      <b-notification type="is-warning is-light mt-5" has-icon :closable="false">
        <i class="fas fa-info mr-4"></i>
        No debe olvidar su contraseña, puede que se solicite más adelante.
      </b-notification>
      <p class="mt-4 mb-3">
        Signar almacena su clave de firma. Si desea almacenar su clave de forma personal,
        desmarque la siguiente opción:
      </p>
      <b-field class="mx-1">
        <b-checkbox v-model="delegateKey">
          Delegar almacenamiento de clave a Signar
        </b-checkbox>
      </b-field>
      <div class="buttons is-right mt-6">
        <b-button class="is-light mr-2" @click="goBackStep0">
          Volver
        </b-button>
        <button @click="createKeyPair" class="button is-primary">
          Continuar&nbsp;&nbsp;
          <i class="fas fa-arrow-right fa-sm"></i>
        </button>
      </div>
    </div>

    <div class="box" v-else-if="step === 2">
      <h1 class="subtitle is-5">Descarga de clave</h1>
      <p class="my-3">
        Ha decidido almacenar su clave de forma personal.
        Para continuar con la solicitud, debe descargar y guardar de forma segura su clave.
      </p>
      <hr>
      <div class="is-flex is-flex-direction-row is-align-items-center px-4">
        <i class="fas fa-key fa-lg mx-2"></i>
        <p class="is-size-5 has-text-weight-bold is-flex-grow-1 mx-2">Clave de firma</p>
        <a @click="privateKeyDownloaded = true" class="button"
          :href="`data:application/octet-stream;charset=utf-8;base64,${privateKeyBase64}`"
          download="private_key" target="_blank">
          <i class="fas fa-download"></i>&nbsp;&nbsp;Descargar
        </a>
      </div>
      <hr>
      <b-notification type="is-warning is-light" has-icon :closable="false">
        <i class="fas fa-info mr-4"></i>
        No debe perder su clave de firma, puede que se solicite más adelante.
      </b-notification>
      <div class="buttons is-right mt-6">
        <b-button class="is-light mr-2" @click="goBackStep1">
          Volver
        </b-button>
        <button @click="createAndSendCSR" class="button is-primary"
          :disabled="!delegateKey && !privateKeyDownloaded">
          Continuar&nbsp;&nbsp;
          <i class="fas fa-arrow-right fa-sm"></i>
        </button>
      </div>
    </div>

    <div class="box" v-else-if="step === 3">
      <h1 class="subtitle is-5">Fin</h1>
      <div v-if="!error">
        <div class="section has-text-centered">
          <i class="fas fa-check-circle has-text-success fa-4x mb-4"></i>
          <h1 class="title is-4 has-text-success">Ha llegado al final del procedimiento</h1>
          <h1 class="subtitle is-5 has-text-success">Su solicitud está pendiente de revisión</h1>
        </div>
        <b-notification type="is-warning is-light" has-icon :closable="false">
          <i class="fas fa-info mr-4"></i>
          Recuerde imprimir el <b>Acuerdo de Suscriptores</b>, firmarlo y acercarlo a la oficina correspondiente.
        </b-notification>
      </div>
      <div class="section has-text-centered" v-else>
        <i class="fas fa-times-circle has-text-danger fa-4x mb-4"></i>
        <h1 class="title is-4 has-text-danger">Error al registrar la solicitud</h1>
        <h1 class="subtitle is-5 has-text-info">Puede reintentar el proceso o comuníquese con Soporte</h1>
      </div>
      <div class="buttons is-right mt-6">
        <b-button class="is-primary" @click="$router.replace({name:'certificadosListado'})">
          Volver a mis certificados
        </b-button>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data () {
    return {
      step: 0,
      password: '',
      confirmedPassword: '',
      error: false,
      delegateKey: true,
      privateKeyDownloaded: false,
      privateKeyBase64: null,
      // publicKeyBase64: null,
      keyPair: null,
      isLoading: null,
      usuario: null,
      instrucciones: null
    }
  },
  beforeMount: function () {
    this.isLoading = true
    Promise
      .all([
        this.$http
          .get(`/usuario/${this.user.id}`),
        this.$http
          .get(`/certificado?usuario_id=${this.user.id}&organizacion_id=${this.user.organization}&estados=aprobado,pendiente,vigente`),
        this.$http
          .get(`/organizacion/${this.user.organization}/modulo?modulo_id=aureg`)
      ])
      .then(responses => {
        this.usuario = responses[0].data
        const moduloFound = responses[2].data.find(mod => mod.modulo_id === 'aureg')
        this.instrucciones = moduloFound ? moduloFound.parametros.instrucciones : ''
      })
      .catch(() => {
        this.$buefy.snackbar.open({
          message: 'Error al recuperar los datos del usuario',
          type: 'is-danger'
        })
      })
      .finally(() => {
        this.isLoading = false
      })
  },
  beforeDestroy: function () {
    window.onbeforeunload = null
  },
  beforeRouteLeave (to, from, next) {
    if (this.step === 2) {
      this.showAlert('Procedimiento en progreso',
        'No puede salir del procedimiento hasta que haya finalizado. Por favor, continúe con la solicitud.')
      next(false)
    } else {
      next()
    }
  },
  computed: {
    user: function () {
      return this.$store.getters.getUser
    }
  },
  methods: {
    nextStep () {
      switch (this.step) {
        case 0:
          this.step = 1
          break
        case 1:
          if (!this.delegateKey) {
            this.step = 2
          } else {
            this.step = 3
          }
          break
        case 2:
          this.step = 3
          break
      }
    },
    goBackStep0 () {
      this.step = 0
    },
    goBackStep1 () {
      this.keyPair = null
      this.privateKeyBase64 = null
      this.step = 1
    },
    createKeyPair () {
      window.onbeforeunload = function () {
        return '¿Esta seguro que desea cerrar la página?'
      }
      this.$validator.validateAll('passwords').then(isValid => {
        if (!isValid) {
          this.showAlert('Error de contraseña', 'Debes ingresar y confirmar una contraseña válida.')
        } else {
          this.$forge.rsa.generateKeyPair({ bits: 2048, workers: 2 }, (err, keypair) => {
            if (err) {
              this.showAlert('Error de solicitud', 'Ocurrió un error en la solicitud. Comuníquese con el administrador.')
            } else {
              this.keyPair = keypair
              var privateKeyEncrypted = this.$forge.pki.encryptRsaPrivateKey(keypair.privateKey, this.password)
              this.privateKeyBase64 = this.$forge.util.encode64(privateKeyEncrypted)
              // var publicKeyPEM = this.$forge.pki.publicKeyToPem(keypair.publicKey)
              // this.publicKeyBase64 = this.$forge.util.encode64(publicKeyPEM)
              if (this.delegateKey) {
                this.createAndSendCSR()
              } else {
                this.nextStep()
              }
            }
          })
        }
      })
    },
    showAlert (title, message) {
      this.$buefy.dialog.alert({
        title: title,
        message: message,
        type: 'is-danger',
        ariaModal: true
      })
    },
    createAndSendCSR () {
      this.isLoading = true
      var csrBase64 = this.createCSR()
      this.sendCSR(csrBase64)
      this.isLoading = false
      this.nextStep()
    },
    createCSR () {
      var structure = {
        countryName: 'AR',
        commonName: `${this.usuario.nombre} ${this.usuario.apellido}`,
        serialName: `CUIL ${this.usuario.cuil}`
      }
      var subjectBody = Object.keys(structure).map(k => {
        return {
          name: k,
          value: structure[k],
          valueTagClass: this.$forge.asn1.Type.UTF8
        }
      })
      var csr = this.$forge.pki.createCertificationRequest()
      csr.publicKey = this.keyPair.publicKey
      csr.setSubject(subjectBody)
      csr.setAttributes([
        {
          name: 'extensionRequest',
          extensions: [
            {
              name: 'subjectAltName',
              altNames: [{ type: 1, value: this.usuario.email }]
            }
          ]
        }
      ])
      csr.sign(this.keyPair.privateKey)
      csr.verify()
      var pem = this.$forge.pki.certificationRequestToPem(csr)
      return this.$forge.util.encode64(pem)
    },
    sendCSR (csrBase64) {
      var request = {
        csr: csrBase64,
        keystore: this.delegateKey ? this.privateKeyBase64 : null,
        usuario_id: this.user.id
      }
      this.$http
        .post('/certificado', request)
        .then(() => {
          window.onbeforeunload = null
          this.$emit('disable-new-request-button')
        })
        .catch(err => {
          this.password = this.confirmedPassword = ''
          this.error = true
          this.$buefy.snackbar.open({
            message: err.mensaje,
            type: 'is-danger',
            duration: 5000
          })
        })
    }
  }
}
</script>
