import http from '@/http'

const otroDocumentoModule = {
  namespaced: true,
  state: {
    otroDocumento: null
  },
  mutations: {
    otroDocumento (state, file) {
      state.otroDocumento = file
    },
    unload (state) {
      state.otroDocumento = null
    }
  },
  getters: {},
  actions: {
    fetchOtroDocumento ({ commit }, id) {
      return http.get(`/otro-documento/${id}`)
        .then(res => {
          console.info('[VUEX] GET OK - Got Otro Documento')
          commit('otroDocumento', res.data)
          return res
        })
    },
    unload (context) {
      context.commit('unload')
    }
  }
}

export default otroDocumentoModule
