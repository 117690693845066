<template>
  <section>
    <div class="box">
      <h1 class="subtitle is-4">Oficial de Registro</h1>
      <h1 class="title is-3">Certificados de la organización</h1>
      <div class="content">
        <p>Solicitudes y certificados de los usuarios de la organización.</p>
      </div>
    </div>
    <Filtros @set-query="setQuery" @refresh="refresh"/>
    <Listado ref="listado" />
  </section>
</template>
<script>
import Filtros from '@/views/certificados/oficial-registro/Filtros'
import Listado from '@/views/certificados/oficial-registro/Listado'
export default {
  components: {
    Filtros,
    Listado
  },
  methods: {
    setQuery: function (query) {
      this.$refs.listado.setQuery(query)
    },
    refresh: function () {
      this.$refs.listado.refresh()
    }
  }
}
</script>
