<template>
   <div class="card">
          <div class="card-content has-text-centered">
            <h1 class="title is-3">Eliminar copia de seguridad del certificado</h1>
            <h2 class="subtitle is-5">¿Está seguro que desea eliminar su copia de seguridad?</h2>
          </div>
          <div class="card-footer">
            <a class="card-footer-item" type="button" @click="$parent.close()">Cancelar</a>
        <a class="card-footer-item has-text-danger has-text-weight-bold is-uppercase" @click.prevent="deleteCert">Eliminar</a>
          </div>
        </div>
</template>

<script>
export default {
  methods: {
    deleteCert () {
      this.$emit('deleteCert')
      this.$parent.close()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
