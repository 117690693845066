export default {
  STRINGS: {
    BUTTONS: {
      CLEAN_FILTERS: 'Limpiar filtros',
      CLOSE: 'Cerrar',
      RETRY: 'Recargar',
      SEARCH: 'Buscar'
    },
    COMMON_WORDS: {
      ADMIN_LOWERCASE: 'administrador',
      ADMIN_SLASH_USER_LOWERCASE: 'administrador-usuario',
      ASPECTS: 'Aspectos',
      COMPOUND_UNITS_CAMEL_CASE: 'unidadCompuesta',
      COMPOUND_UNITS_DASH_CASE: 'unidad-compuesta',
      COMPOUND_UNITS: 'Unidades compuestas',
      DASH_ALL_DASH: '- Todos -',
      DOCUMENT_LOWERCASE: 'documento',
      DOCUMENTAL_SERIE: 'Serie documental',
      DOCUMENTS: 'Documentos',
      FOLDER_LOWERCASE: 'carpeta',
      FOLDERS: 'Carpetas',
      KEYDOWN_LOWERCASE: 'keydown',
      METADATA: 'Metadatos',
      OTHER_DOCUMENT_CAMEL_CASE: 'otroDocumento',
      OTHER_DOCUMENTS: 'Otros documentos',
      OTHER_FILTERS: 'Otros filtros',
      RECEIPT_LOWERCASE: 'recibo',
      RECEIPTS: 'Recibos',
      ROOT_FOLDER: 'Carpeta raíz',
      SUBUNIT_LOWERCASE: 'subunidad',
      SUBUNITS: 'Subunidades',
      TYPE: 'Tipo',
      USER_LOWERCASE: 'usuario'
    },
    DIALOGS: {
      SELECT_ALL_ORGANIZATION: {
        CONFIRM_BUTTON: 'Seleccionar toda la organización',
        MESSAGE: '¿Está seguro de que desea subir documentos a <b>toda la organización</b>? Se enviará un mail a cada usuario.',
        TITLE: 'Seleccionar todos los usuarios'
      }
    },
    DOCUMENTS: {
      STATUS: {
        ANY_STATE: 'Cualquier estado',
        AVAILABLE_LOWERCASE: 'disponible',
        AVAILABLE: 'Disponible',
        CREATED: 'Creado',
        PENDING_LOWERCASE: 'pendiente',
        PENDING_SIGNATURE: 'Pendiente de firma',
        SIGNED_LOWERCASE: 'firmado',
        SIGNED: 'Firmado'
      },
      TYPES: {
        ANY_TYPE: 'Cualquier tipo'
      }
    },
    ERRORS: {
      COMMUNICATION_ERROR_PLEASE_REFRESH_PAGE: 'Error en la comunicación. Por favor recargue la página.',
      COULD_NOT_DOWNLOAD_RECORD: 'No se pudo descargar el acta de alta. Vuelva a intentarlo más tarde o contáctese con Lyris para más información.',
      CAN_NOT_DELETE_ROOT_FOLDER_FROM_SHORTCUTS: 'No puede eliminar la carpeta raíz de la organización desde los accesos directos.',
      DEFAULT: 'Ocurrió un error inesperado, por favor vuelva a intentarlo más tarde',
      DO_NOT_HAVE_MINIMUM_LEVEL_PERMISSION: {
        DEFAULT: 'No cuenta con el nivel de permiso necesario para ejecutar la acción',
        OVER_DOCUMENT: 'No cuenta con el nivel de permiso suficiente sobre el documento',
        OVER_FOLDER: 'No cuenta con el nivel de permiso suficiente sobre la carpeta',
        OVER_SOME_DOCUMENTS: 'No cuenta con el nivel de permiso suficiente sobre algunos documentos',
        TO_CREATE_COMPOSITE_UNIT: 'No cuenta con el nivel de permiso necesario para crear unidades compuestas',
        TO_CREATE_FOLDER: 'No cuenta con el nivel de permiso necesario para crear carpetas',
        TO_CREATE_OR_UPLOAD_FILES: 'No cuenta con el nivel de permiso necesario para crear o subir archivos',
        TO_DELETE_DOCUMENT: 'No cuenta con el nivel de permiso necesario para eliminar el documento',
        TO_DELETE_FOLDER: 'No cuenta con el nivel de permiso necesario para eliminar la carpeta',
        TO_UPLOAD_DOCUMENT: 'No cuenta con el nivel de permiso necesario para subir documentos'
      },
      LIST_OTROS_DOCUMENTOS: {
        CANT_DETERMINE_SCOPE: 'No se pudo determinar el destinatario'
      },
      NO_RESULTS_FOR: 'No hay resultados para',
      RECEIVER_COULD_NOT_BE_RECOVERED: 'No se pudo recuperar el destinatario.'
    },
    LABELS: {
      ALL_USERS_FROM: 'Todos los usuarios de',
      BLANK_FIELD_REPRESENTS_ANY_DAY_MONTH_OR_YEAR: 'El campo en blanco representa cualquier día, mes o año'
    },
    LISTS: {
      END_OF_LIST: '~ Fin del listado ~',
      NO_RESULTS: 'No hay documentos que cumplan con los filtros de búsqueda.',
      NOT_AVAILABLE: 'N/A'
    },
    LOADING: {
      LOADING_TEXT_WITH_SLASHES: '- Cargando -',
      LOADING_TEXT: 'Cargando...'
    },
    LOG_EVENTS: {
      OTROS_DOCUMENTOS: {
        ADDED_KEYDOWN_EVENT_LISTENER: 'Otros documentos: Added keydown event listener',
        ENTER_KEY_PRESSED_UPDATING_LIST: 'Otros documentos: Enter key pressed: Updating list',
        REMOVED_KEYDOWN_EVENT_LISTENER: 'Otros documentos: Removed keydown event listener'
      }
    },
    MESSAGES: {

    },
    PERMISSIONS: {
      STATUS: {
        OTROS_DOCUMENTOS_ADMIN_AREA: 'otros-admin-area',
        OTROS_DOCUMENTOS_RECEIVER: 'otros-user',
        OTROS_DOCUMENTOS_UPLOADER: 'otros-admin'
      }
    },
    PLACEHOLDERS: {
      DAY: 'Día',
      MONTH: 'Mes',
      WRITE_DOCUMENT_NAME: 'Escriba el nombre del documento',
      WRITE_NAME_OR_SURNAME: 'Escriba nombre o apellido',
      WRITE_NAME: 'Escriba nombre',
      YEAR: 'Año'
    },
    ROLES: {
      ADMINISTRADOR_DE_ASPECTOS: { ID: 'aspec-admin', NAME: 'Administrador de Aspectos' },
      ADMINISTRADOR_DE_OTROS_DOCUMENTOS: { ID: 'otros-admin', NAME: 'Administrador de Otros Documentos' },
      ADMINISTRADOR_DE_SERIES: { ID: 'serie-admin', NAME: 'Diseñador de Series Documentales' },
      ADMINISTRADOR: { ID: 'admin', NAME: 'Administrador' },
      AUDITOR: { ID: 'auditor', NAME: 'Auditor' },
      EMPLEADO: { ID: 'empleado', NAME: 'Empleado' },
      EMPLEADOR: { ID: 'empleador', NAME: 'Empleador' },
      LYRIS: { ID: 'lyris', NAME: 'Lyris' },
      LYRIS_OR: { ID: 'lyris-or', NAME: 'Lyris OR' },
      LYRIS_SOPORTE: { ID: 'lyris-soporte', NAME: 'Lyris Soporte' },
      OFICIAL_DE_REGISTRO: { ID: 'or', NAME: 'Oficial de Registro' },
      SUSCRIPTOR: { ID: 'suscriptor', NAME: 'Suscriptor' },
      USUARIO_DE_ASPECTOS: { ID: 'aspec-user', NAME: 'Usuario de Aspectos' },
      USUARIO_DE_OTROS_DOCUMENTOS: { ID: 'otros-user', NAME: 'Usuario de Otros Documentos' },
      USUARIO_DE_SERIES: { ID: 'serie-user', NAME: 'Usuario de Series Documentales' },
      USUARIO: { ID: 'usuario', NAME: 'Usuario' }
    },
    SUBTITLES: {
      FILTERS_GENERAL_SEARCH: {
        CHOOSE_FOLDER_WHERE_RECEIPTS_WILL_SAVE: 'Elija la carpeta donde se guardaran los recibos.',
        CHOOSE_FOLDER: 'Elegir carpeta',
        CHOOSE_ROOT_FOLDER: 'Elija la carpeta raiz',
        ONLY_CONFIDENTIAL: 'Sólo confidenciales'
      },
      FILTERS_OTROS_DOCUMENTOS: {
        FOR_ALL_ORGANIZATION: 'Dirigidos a toda la organización',
        FOR_AREA: 'Por área',
        FOR_DATE: 'Por fecha',
        FOR_DOCUMENT_NAME: 'Por nombre del documento',
        FOR_DOCUMENT_STATUS: 'Por estado del documento',
        FOR_DOCUMENT_TYPE: 'Por tipo de documento',
        FOR_EMPLOYEE: 'Por empleado',
        FOR_MY_STATE_WITH_THE_DOCUMENT: 'Por mi estado con el documento',
        SEE_ONLY_MY_DOCUMENTS: 'Ver sólo mis documentos'
      }
    },
    TABLES: {
      HEADERS: {
        DOCUMENTS: {
          DATE: 'Fecha',
          DOCUMENTS: 'Documentos',
          SCOPE: 'Alcance',
          SIGN_STATE: 'Estado de firmas',
          TYPE: 'Tipo'
        }
      },
      OTRO_DOCUMENTO_INFORMATION: {
        SCOPE: 'Alcance',
        STATE: 'Estado',
        TYPE: 'Tipo',
        UPLOAD_DATE: 'Fecha de subida a la plataforma'
      }
    },
    TITLES: {
      OPTIONS_FOR_RRHH: 'Opciones para RRHH',
      SEARCH_FILTERS: 'Filtros de búsqueda'
    },
    TOOLTIPS: {
      CURRENT_CERTIFICATE_VIEW: {
        DOWNLOAD_RECORD_BUTTON_DISABLED: 'Si desea descargar su acta de alta debe contactarse con un Oficial de Registro.'
      },
      INFORMATION_OTRO_DOCUMENTO: {
        SIGNS_PROGRESS: 'Este progreso cuenta también la firma del administrador al momento de subir el documento.'
      },
      LIST_OTROS_DOCUMENTOS: {
        EMPLOYEE_SIGNED_DOCUMENT: 'Firmó el documento, pero faltan usuarios por firmar',
        ERROR_WHEN_GETTING_SIGN_STATUS: 'Error al obtener el estado de firma del documento'
      },
      MODAL_UPLOAD_OTRO_DOCUMENTO: {
        ALL_ORGANIZATION_CANT_ENABLE_IF_SIGN_IS_REQUIRED: 'No puede requerir firma de los documentos si selecciona toda la organización.',
        ALL_ORGANIZATION_DISABLED: 'Puede seleccionar toda la organización sólo si no ha seleccionado ningún usuario ni área.',
        AREAS_DISABLED: 'Puede seleccionar áreas sólo si no ha seleccionado ningún usuario ni toda la organización.',
        IS_SIGN_REQUIRED: 'Marque esta casilla si desea que el usuario destinatario del documento deba firmarlo una vez subido a la plataforma.',
        USERS_DISABLED: 'Puede seleccionar usuarios sólo si no ha seleccionado ningún área ni toda la organización.'
      }
    },
    URLS: {

    },
    WARNINGS: {
      ALL_ORGANIZATION_CANT_ENABLE_IF_SIGN_IS_REQUIRED: 'No puede requerir firma de los documentos si selecciona toda la organización.',
      MULTIPLE_SCOPE_TYPES_SELECTED: 'No puede subir un documento a usuarios, areas y/o toda la organización al mismo tiempo.',
      NO_RECEIVER_SELECTED: 'Debe seleccionar al menos un destinatario del documento.',
      SIGN_CANT_BE_REQUIRED_WITHOUT_GLOBAL_DOCUMENT_FLAG: 'No puede requerir firma de los documentos sin especificar el tipo.'
    }
  }
}
