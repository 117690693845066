import store from '@/store'

// Panel
import Perfil from './Perfil'
import Informacion from './Informacion'
import Firmador from './Firmador'
import Certificado from './Certificado'
import Clave from './Clave'

const routes = [
  {
    path: '/perfil',
    component: Perfil,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      const usrId = store.getters.getUser.id
      const orgId = store.getters.getUser.organization
      Promise.all([
        store.dispatch('profile/fetchUser', usrId),
        store.dispatch('organizacion/fetchOrganizacion', orgId),
        store.dispatch('organizacion/fetchRoles', orgId),
        store.dispatch('organizacion/fetchAreas', orgId)
      ])
        .then(responses => {
          console.info('[ROUTER] Data fetch success. Continue to route')
          next()
        })
        .catch(error => {
          console.error('[ROUTER] Data fetch failed', error)
          next({ name: 'error' })
        })
    },
    children: [
      {
        path: '',
        component: Informacion,
        name: 'perfilInformacion',
        props: true
      },
      {
        path: '/certificado',
        component: Certificado,
        name: 'perfilCertificado',
        props: true
      },
      {
        path: '/firmador',
        component: Firmador,
        name: 'perfilFirmador',
        props: true
      },
      {
        path: '/clave',
        component: Clave,
        name: 'perfilClave',
        props: true
      }
    ]
  }
]
export default routes
