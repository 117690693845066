<template>
  <div id="home" class="is-flex is-flex-direction-column is-justify-content-space-between is-align-content-center">
    <section class="section">
      <div class="container">
        <img src="/statics/signar-logo-grey.svg" class="image is-centered mt-5" alt="">
        <br>
        <buscador :active.sync="buscadorActive"></buscador>
      </div>
    </section>
    <recent-docs v-if="$store.getters.hasAnyRole(['user', 'lyris'])"></recent-docs>
  </div>
</template>

<script>
import Buscador from '@/components/portal/buscador/Buscador'
import RecentDocs from '@/components/utils/RecentDocs'

export default {
  name: 'inicio',
  data () {
    return {
      buscadorActive: false
    }
  },
  components: {
    Buscador,
    RecentDocs
  },
  beforeMount: function () {
    if (this.session == null) {
      this.$router.push({ name: 'login' })
    }
  },
  computed: {
    session: function () {
      return this.$store.state.session
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#home {
  height: calc(100vh - 3.5rem)
}
img.image {
  width: 100px;
  margin: 0 auto 20px;
}
</style>
