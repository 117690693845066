<template>
  <section>
    <div class="box">
      <h1 class="subtitle is-4">Mis certificados</h1>
      <h1 class="title is-3">Certificado</h1>

      <div v-for="(cert, index) in filteredCerts" v-bind:key="index">
        <b-tag v-if="cert.estado === 'vigente' && cert.por_vencer" class="mb-3"
          type="is-warning" size="is-medium">
          ¡El certificado está por expirar!
        </b-tag>
        <p v-if="cert.estado === 'pendiente'" class="is-400 is-italic">
          Pendiente de revisión
        </p>
        <p v-if="cert.estado === 'pendiente'" class="mt-1">
          Esperando que un Oficial de Registro autorice la emisión.
        </p>
        <p v-if="cert.estado === 'aprobado'" class="is-500 mb-1">
          Tu solicitud ha sido aprobada
        </p>
        <p v-if="cert.estado === 'aprobado'" class="mb-3">
          Para finalizar el proceso, presione el botón Obtener certificado.
        </p>

        <div class="is-flex is-flex-direction-row is-align-items-center pb-4"
          v-if="cert.estado === 'vigente' || cert.estado === 'aprobado'">
          <router-link v-if="cert.estado === 'aprobado'" class="button is-success"
            :to="{name:'certificadoAceptacion', params: { idCert: cert.id }}">
            Obtener certificado
          </router-link>
          <router-link v-if="cert.estado === 'vigente' && cert.por_vencer" class="button is-success is-outlined mr-4"
            :to="{name: 'certificadosRenovacion', params: { idCert: cert.id }}">
            <i class="fas fa-redo"></i>&nbsp;&nbsp;Renovar
          </router-link>
          <router-link v-if="cert.estado === 'vigente'" class="button is-danger is-outlined mr-4"
            :to="({name: 'certificadosRevocacion', params: { idCert: cert.id }})">
            <i class="fas fa-times"></i>&nbsp;&nbsp;Revocar
          </router-link>
        </div>
        <div v-else class="py-1"></div>

        <div class="is-flex is-flex-direction-column">
          <span v-if="cert.estado === 'pendiente'">
            <span class="is-500">Fecha de solicitud:</span> {{ dateFormat(cert.created_at) }}
          </span>
          <span v-if="cert.estado !== 'pendiente'">
            <span class="is-500">Fecha de emisión:</span> {{ dateFormat(cert.fecha_emision) }}
          </span>
          <span class="my-1" v-if="cert.estado !== 'pendiente'">
            <span class="is-500">Fecha de vencimiento:</span> {{ dateFormat(cert.fecha_vencimiento) }}
          </span>
          <span v-if="cert.estado !== 'pendiente'" class="is-size-6 is-400">
            <span class="is-500">Número de serie:</span> {{ cert.numero_serie }}
          </span>
        </div>
        <hr v-if="filteredCerts.length > 1">
      </div>

      <h1 v-if="!filteredCerts.length > 0" class="mt-1 subtitle is-5 has-text-black">
        No tienes un certificado vigente, puedes obtener uno iniciando una
        <router-link :to="{name: 'certificadosNuevaSolicitud'}">nueva solicitud</router-link>.
      </h1>
    </div>

    <div class="box mb-4">
      <h1 class="subtitle is-5 mb-4">Registro</h1>
      <div class="p-2">
        <div class="is-flex is-flex-direction-row is-align-items-center mb-2">
          <span class="has-text-primary is-500 mr-4">Filtros</span>
          <b-dropdown v-model="selectedLabels" multiple aria-role="list">
            <template #trigger>
              <b-button type="is-white" icon-right="caret-down">
                Estado ({{ selectedLabels.length }})
              </b-button>
            </template>
            <b-dropdown-item :value="label" v-for="(label, i) in availableLabels" :key="`label-${i}`"
              aria-role="listitem" @click="filterCerts">
              <span>{{ label }}</span>
            </b-dropdown-item>
          </b-dropdown>
          <b-button class="is-primary ml-auto" icon-right="fas fa-sync" @click="emptyFilters" />
        </div>
        <span v-if="selectedLabels.length">
          <span class="is-500 is-italic">Seleccionados: </span>
          {{ selectedLabels.join(', ') }}
        </span>
      </div>
    </div>

    <div class="box has-text-centered" v-if="isLoading">
      <loading-spin></loading-spin>
    </div>

    <template v-else>
    <div class="box mb-4" v-for="(cert, index) of certList" :key="index">
        <div class="is-flex is-flex-direction-row is-align-items-center">
          <div class="is-flex-grow-1">
            <span class="is-400 is-italic" v-if="cert.estado === 'pendiente'">
              Pendiente de revisión
            </span>
            <span class="is-400 is-italic" v-if="cert.estado === 'rechazado'">
              Solicitud rechazada
            </span>
            <span class="is-size-6 is-400" v-if="cert.estado !== 'pendiente' && cert.estado !== 'rechazado'">
              <span class="is-size-6 is-500">Número de serie:</span> {{ cert.numero_serie }}
            </span>
          </div>
          <b-tag rounded type="is-warning is-light" size="is-medium" class="is-500"
            v-if="cert.estado === 'pendiente'">Pendiente</b-tag>
          <b-tag rounded type="is-success is-light" size="is-medium" class="is-500"
            v-if="cert.estado === 'aprobado'">Aprobado</b-tag>
            <b-tag rounded type="is-success is-light" size="is-medium" class="is-500"
            v-if="cert.estado === 'vigente'">Vigente</b-tag>
          <b-tag rounded type="is-danger is-light" size="is-medium" class="is-500"
            v-if="cert.estado === 'revocado'">Revocado</b-tag>
          <b-tag rounded type="is-danger is-light" size="is-medium" class="is-500"
            v-if="cert.estado === 'rechazado'">Rechazado</b-tag>
          <b-tag rounded type="is-light" size="is-medium" class="is-500"
            v-if="cert.estado === 'vencido'">Vencido</b-tag>
        </div>

        <!-- <div class="is-flex is-flex-direction-row is-align-items-center py-4"
          v-if="cert.estado === 'vigente' || cert.estado === 'aprobado'">
          <router-link v-if="cert.estado === 'aprobado'" class="button is-success"
            :to="{name:'certificadoAceptacion', params: { idCert: cert.id }}">
            Obtener certificado
          </router-link>
          <router-link v-if="cert.estado === 'vigente' && cert.por_vencer" class="button is-success is-outlined mr-4"
            :to="{name: 'certificadosRenovacion', params: { idCert: cert.id }}">
            <i class="fas fa-redo"></i>&nbsp;&nbsp;Renovar
          </router-link>
          <router-link v-if="cert.estado === 'vigente'" class="button is-danger is-outlined mr-4"
            :to="({name: 'certificadosRevocacion', params: { idCert: cert.id }})">
            <i class="fas fa-times"></i>&nbsp;&nbsp;Revocar
          </router-link>
          <b-tag class="ml-auto" v-if="cert.estado === 'vigente' && cert.por_vencer"
            type="is-warning" size="is-medium">
            ¡El certificado está por expirar!
          </b-tag>
        </div>
        <div v-else class="py-1"></div> -->

        <div class="is-flex is-flex-direction-column py-1">
          <span v-if="cert.estado === 'pendiente' || cert.estado === 'rechazado'">
            <span class="is-500">Fecha de solicitud:</span> {{ dateFormat(cert.created_at) }}
          </span>
          <span v-if="cert.estado !== 'pendiente' && cert.estado !== 'rechazado'">
            <span class="is-500">Fecha de emisión:</span> {{ dateFormat(cert.fecha_emision) }}
          </span>
          <span v-if="cert.estado !== 'pendiente' && cert.estado !== 'rechazado'" class="mt-1">
            <span class="is-500">Fecha de vencimiento:</span> {{ dateFormat(cert.fecha_vencimiento) }}
          </span>
          <span v-if="cert.estado === 'revocado'" class="mt-1">
            <span class="is-500">Fecha de revocación:</span> {{ dateFormat(cert.fecha_revocacion) }}
          </span>
        </div>
      </div>
    </template>

    <div class="box has-text-centered" v-if="certList.length === 0 && !isLoading">
      <section class="section is-mono">
        <span class="tag is-rounded is-medium">
          <i class="fas fa-info fa-fw"></i>&nbsp;Sin certificados para mostrar
        </span>
      </section>
    </div>

    <paginator :resource="url" ref="paginator" @update="updateResource" :loading.sync="isLoading" />
  </section>
</template>
<script>
import Paginator from '@/components/utils/Paginator'
import LoadingSpin from '@/components/utils/LoadingSpin'
import constants from '../../constants'
export default {
  components: {
    Paginator,
    LoadingSpin
  },
  data () {
    return {
      constants: constants,

      isLoading: false,
      selectedLabels: [],
      availableLabels: [
        'Pendiente',
        'Aceptado',
        'Aprobado',
        'Rechazado',
        'Vigente',
        'Revocado',
        'Vencido',
        'Pre-aprobado'
      ],
      certList: [],
      filteredCerts: [],
      razon_revocacion: '',
      queryOrganizacionId: null,
      queryEstados: null,
      queryUsuarioId: null,
      queryAcId: null,
      reasons: null
    }
  },
  beforeMount: function () {
    this.queryUsuarioId = this.user.id
    this.queryOrganizacionId = this.user.organization
    this.fetchReasons()
  },
  watch: {
    queryOrganizacionId: function (oldVal, newVal) {
      this.isLoading = true
      this.$refs.paginator.fetchData()
    },
    queryEstados: function (oldVal, newVal) {
      this.isLoading = true
      this.$refs.paginator.fetchData()
    },
    queryUsuarioId: function (oldVal, newVal) {
      this.isLoading = true
      this.$refs.paginator.fetchData()
    },
    queryAcId: function (oldVal, newVal) {
      this.isLoading = true
      this.$refs.paginator.fetchData()
    },
    certList: function (oldVal, newVal) {
      this.isLoading = true
      this.certList.forEach(cert => {
        if (cert.estado === 'revocado') {
          this.reasons.forEach(r => {
            if (r.id === cert.razon_revocacion) {
              this.razonRevocacion = r.mensaje
            }
          })
          cert.razon_revocacion = this.razonRevocacion
          this.razonRevocacion = ''
        }
      })
      this.isLoading = false
    }
  },
  methods: {
    // updateResource (data) {
    //   this.certList = data
    //   var addCertsAvailables = true
    //   if (!(this.filteredCerts.length > 0)) {
    //     data.forEach(e => {
    //       if (addCertsAvailables && e.estado === 'vigente') {
    //         this.filteredCerts.push(e)
    //       }
    //     })
    //   }
    // },
    updateResource (data) {
      this.certList = data
      if (!(this.filteredCerts.length > 0)) {
        data.forEach(c => {
          if (['pendiente', 'aprobado', 'vigente'].includes(c.estado)) {
            this.$emit('disable-new-request-button')
            this.filteredCerts.push(c)
          }
        })
        if (this.filteredCerts.length === 0) this.$emit('enable-new-request-button')
      }
    },
    fetchReasons () {
      this.isLoading = true
      this.$http
        .get('/razon-revocacion')
        .then((res) => {
          this.reasons = res.data
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            message: 'Ocurrió un error al recuperar los certificados',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    filterCerts () {
      this.isLoading = true
      this.queryEstados = ''
      Object.keys(this.selectedLabels).forEach(k => {
        var paramToLowerCase = this.selectedLabels[k].toLowerCase()
        var paramCleaned = paramToLowerCase.replace('-', '')
        this.queryEstados ? this.queryEstados += `,${paramCleaned}` : this.queryEstados = `${paramCleaned}`
      })
    },
    emptyFilters () {
      this.selectedLabels = []
      this.filterCerts()
      this.isLoading = false
    },
    // openPubKeyDialog (cert) {
    //   this.$buefy.modal.open({
    //     parent: this,
    //     content: `
    //       <div class="box">
    //         <button class="delete is-small is-pulled-right" @click="$parent.close()"></button>
    //         <h1 class="title is-3">Clave pública</h1>
    //         <h1 class="subtitle is-5 mt-2 is-family-monospace">CN = ${this.user.name} ${this.user.surname}</h1>
    //         <pre>${this.publicKey}</pre>
    //       </div>`
    //   })
    // },
    // openSerialNumberModal (cert) {
    //   this.$buefy.modal.open({
    //     parent: this,
    //     content: `
    //       <div class="box">
    //         <button class="delete is-small is-pulled-right" @click="$parent.close()"></button>
    //         <h1 class="title is-3">Número de serie</h1>
    //         <h1 class="subtitle is-5 mt-1 is-family-monospace">CN = ${this.user.name} ${this.user.surname}</h1>
    //         <pre class='is-family-monospace is-size-5'>${cert.numero_serie}</pre>
    //       </div>`
    //   })
    // },
    dateFormat (date) {
      return new Date(date).toLocaleDateString('fr')
    },
    // dnToString (dn) {
    //   var dnDecoded = decodeURIComponent(dn.replaceAll('\\x', '%'))
    //   const dnArray = dnDecoded.split('/')
    //   return (`${dnArray[1]}, ${dnArray[2]}, ${dnArray[3]}`)
    // },
    reasonString (reason) {
      return `${reason.charAt(0).toUpperCase()}${reason.slice(1)}`
    }
  },
  computed: {
    queryObject: function () {
      const obj = {}
      if (this.queryUsuarioId) {
        obj.usuario_id = this.queryUsuarioId
      }
      if (this.queryEstados) {
        obj.estados = this.queryEstados
      }
      if (this.queryOrganizacionId) {
        obj.organizacion_id = this.queryOrganizacionId
      }
      if (this.queryAcId) {
        obj.ac_id = this.queryAcId
      }
      return obj
    },
    user: function () {
      return this.$store.getters.getUser
    },
    url: function () {
      const queryString = Object.keys(this.queryObject)
        .map(i => { return i + '=' + this.queryObject[i] })
        .join('&')
      if (queryString.length) { return '/certificado?sort=created_at&direction=desc&' + queryString }
      return '/certificado?sort=created_at&direction=desc'
    }
  }
}
</script>
