<template>
  <div class="card">
    <div class="card-content">
      <p class="title is-3">Dar de baja usuario</p>
      <p class="subtitle is-5">¿Está seguro de que desea dar de baja al usuario {{user.acceso}}?</p>
      <div class="block">
        <p class="mb-2">
          <b-tooltip label="Nombre" position="is-right">
            <i class="fas fa-user fa-fw"></i>&nbsp;{{user.apellido}}, {{user.nombre}}
          </b-tooltip>
        </p>
        <p class="mb-2">
          <b-tooltip label="CUIL" position="is-right">
            <i class="fas fa-id-badge fa-fw"></i>&nbsp;{{user.cuil}}
          </b-tooltip>
        </p>
        <p>
          <b-tooltip label="Email" position="is-right">
            <i class="fas fa-envelope fa-fw"></i>&nbsp;{{user.email}}
          </b-tooltip>
        </p>
      </div>
      <div class="block">
        <div class="field">
          <label class="label">Motivo</label>
          <div class="control">
            <input
              v-model="reason"
              v-validate="'max:250'"
              type="text"
              name="motivo"
              class="input"
              :class="{'is-danger': errors.has('motivo')}">
          </div>
          <span v-if="errors.has('motivo')" class="help is-danger">{{errors.first('motivo')}}</span>
          <p v-else class="help">Breve descripción del motivo de baja (opcional)</p>
        </div>
      </div>
      <b-notification type= "is-info is-light" :closable="false">
        El usuario ya no tendrá acceso pero sus datos e información relacionada <b>perdurarán en el sistema</b>.
        Para el borrado definitivo de los datos o re-activación del usuario, contáctese con personal de Lyris.
      </b-notification>
    </div>
    <div class="card-footer" v-if="isLoading">
      <span class="card-footer-item animated flash slow" type="button">
        <i class="fas fa-sync fa-spin"></i>&nbsp;Dando de baja al usuario
      </span>
    </div>
    <div class="card-footer" v-else>
      <a class="card-footer-item" type="button" @click="$parent.close()">Cancelar</a>
      <a class="card-footer-item has-text-danger has-text-weight-bold is-uppercase" @click.prevent="confirm">Dar de baja</a>
    </div>
  </div>
</template>

<script>
export default {
  props: ['userData'],
  data () {
    return {
      user: null,
      reason: '',
      isLoading: false
    }
  },
  beforeMount: function () {
    this.user = { ...this.userData }
  },
  methods: {
    confirm: function () {
      if (this.errors.has('motivo')) return
      this.isLoading = true
      const body = {
        habilitar: false,
        motivo: this.reason
      }
      this.$http
        .patch(`/usuario/${this.user.id}/acceso`, body)
        .then(res => {
          this.$buefy.snackbar.open({
            message: 'El usuario ha sido dado de baja exitosamente.',
            type: 'is-success'
          })
          this.isLoading = false
          this.$parent.close()
          this.$emit('refresh')
        })
        .catch(error => {
          console.error('Fail', error)
          this.$buefy.snackbar.open({
            message: 'Error inesperado.',
            type: 'is-danger'
          })
        })
    }
  }
}
</script>
