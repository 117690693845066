<template>
  <section>
    <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="true">
      <b-icon pack="fas" icon="sync-alt" size="is-large" custom-class="fa-spin"></b-icon>
    </b-loading>

    <div class="box">
      <h1 class="subtitle is-4">Mis certificados</h1>
      <h1 class="title is-3">Obtener certificado</h1>
      <div class="content" v-if="step === 0">
        <p>
          Ingrese la contraseña de su clave de firma. Si no la recuerda o
          desea cancelar la solcitud presione el botón correspondiente.
        </p>
      </div>
      <div class="content" v-if="step === 1">
        <p>Descargue su certificado de firma.</p>
      </div>
    </div>

    <div class="box" v-if="step === 0">
      <b-message v-if="!loadPrivateKey" type="is-success">
        Su clave de firma está almacenada en Signar.
      </b-message>
      <b-message v-else type="is-danger">
        Su clave de firma no está almacenada en Signar.
      </b-message>
      <div class="columns">
        <div class="column">
          <b-field class="is-normal mb-4" label="Subir clave de firma" v-if="loadPrivateKey">
            <div class="file has-name is-primary">
              <label class="file-label">
                <input class="file-input" type="file" name="resume" id="file" ref="myFiles" @change="readFile">
                <span class="file-cta">
                  <span class="file-icon"><i class="fas fa-upload"></i></span>
                  <span class="file-label">Examinar</span>
                </span>
                <span class="file-name" v-if="fileName">{{ this.fileName }}</span>
              </label>
            </div>
          </b-field>
          <b-field :type="type" :message="errorMessage" label="Contraseña">
            <b-input password-reveal @input="noError" v-model="password" type="password"
              placeholder="Contraseña de su clave de firma"/>
          </b-field>
        </div>
      </div>
      <p class="my-4">
        Signar almacena su certificado de firma. Si desea almacenar
        su certificado de forma personal, desmarque la siguiente opción:
      </p>
      <b-field class="mx-1">
        <b-checkbox v-model="delegateCert">
          Delegar almacenamiento de certificado a Signar
        </b-checkbox>
      </b-field>
      <div class="buttons is-right mt-6">
        <b-button class="is-danger mr-2" @click="cancelCert">
          Cancelar solicitud
        </b-button>
        <b-button class="is-primary" @click="generateCert">
          Continuar&nbsp;&nbsp;
          <i class="fas fa-arrow-right fa-sm"></i>
        </b-button>
      </div>
    </div>

    <div class="box" v-if="step === 1">
      <h1 class="subtitle is-5">Descarga de certificado</h1>
      <p class="my-3">
        Ha decidido almacenar su certificado de forma personal.
        Para continuar, debe descargar su certificado.
      </p>
      <hr>
      <div class="is-flex is-flex-direction-row is-align-items-center px-4">
        <i class="fas fa-file-archive fa-lg mx-2"></i>
        <p class="is-size-5 has-text-weight-bold is-flex-grow-1 mx-2">Certificado de firma</p>
        <a @click="certDownloaded = true" class="button"
          :href="`data:application/octet-stream;charset=utf-8;base64,${p12Base64}`"
          download="certificate.p12" target="_blank">
          <i class="fas fa-download"></i>&nbsp;&nbsp;Descargar
        </a>
      </div>
      <hr>
      <b-notification type="is-warning is-light" has-icon :closable="false">
        <i class="fas fa-info mr-4"></i>
        No debe perder su certificado, lo necesitará para firmar sus documentos.
      </b-notification>
      <div class="buttons is-right mt-6">
        <button  @click="finish" class="button is-primary"
          :disabled="!delegateCert && !certDownloaded">
          Continuar&nbsp;&nbsp;
          <i class="fas fa-arrow-right fa-sm"></i>
        </button>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: ['idCert'],
  data () {
    return {
      type: '',
      errorMessage: '',
      password: '',
      delegateCert: true,
      keystore: null,
      fileName: '',
      step: 0,
      certDownloaded: false,
      p12Base64: null,
      privateKey: null,
      loadPrivateKey: false,
      isLoading: false
    }
  },
  beforeMount () {
    this.fetchKeystore()
  },
  beforeRouteLeave (to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
    if (this.step === 1 && !this.certDownloaded) {
      // TODO Revisar mensaje
      this.showAlert(
        'Procedimiento en progreso',
        'No puede salir del procedimiento hasta que haya sido completado. Por favor, continue con el proceso.'
      )
      next(false)
    } else {
      next()
    }
  },
  methods: {
    fetchKeystore () {
      this.isLoading = true
      this.$http
        .get(`/certificado/${this.idCert}/keystore`)
        .then(res => {
          if (res.data.keystore) {
            this.keystore = this.$forge.util.decode64(res.data.keystore)
          } else {
            this.loadPrivateKey = true
          }
        })
        .catch(err => {
          if (err.estado === 404) {
            this.loadPrivateKey = true
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    readFile () {
      this.isLoading = true
      var file = this.$refs.myFiles.files[0]
      this.fileName = file.name
      const reader = new FileReader()
      reader.readAsText(file)
      reader.onload = () => {
        this.keystore = reader.result
        this.isLoading = false
      }
      reader.onerror = () => {
        this.loadPrivateKey = true
        this.isLoading = false
        this.showSnackbar('Error al procesar la clave de firma', 'is-danger')
      }
    },
    generateCert () {
      if (!this.keystore) {
        this.showAlert(
          'Acción no permitida',
          'Para continuar, debe subir su clave de firma e ingresar la contraseña.'
        )
      } else {
        try {
          this.privateKey = this.$forge.pki.decryptRsaPrivateKey(this.keystore, this.password)
        } catch (err) {
          this.showSnackbar('Error al procesar la clave de firma', 'is-danger')
        }
        if (this.privateKey) {
          this.noError()
          if (this.delegateCert) {
            // TODO If user keystore is null hide confirm
            this.$buefy.dialog.confirm({
              title: 'Guardar certificado en Signar',
              message: 'Si ya cuenta con un certificado almacenado en Signar, este se <b>sobrescribirá</b>. ¿Está seguro que desea continuar?',
              confirmText: 'Continuar',
              cancelText: 'Cancelar',
              hasIcon: true,
              type: 'is-info',
              onConfirm: () => this.createCert(),
              onCancel: () => { }
            })
          } else {
            this.createCert()
          }
        } else {
          this.hasError()
        }
      }
    },
    createCert () {
      this.isLoading = true
      this.$http
        .post(`/certificado/${this.idCert}/obtener`, { tipo: 'aceptacion' })
        .then(res => {
          const chain = [res.data.certificado, res.data.ac_intermedia, res.data.ac_raiz]
          const options = { algorithm: '3des', friendlyName: `${this.user.name} ${this.user.surname}` }
          var p12Asn1 = this.$forge.pkcs12.toPkcs12Asn1(this.privateKey, chain, this.password, options)
          var p12Der = this.$forge.asn1.toDer(p12Asn1).getBytes()
          this.p12Base64 = this.$forge.util.encode64(p12Der)
          if (this.delegateCert) {
            this.sendCert()
          } else {
            this.isLoading = false
            this.step = 1
          }
        })
        .catch(() => {
          this.isLoading = false
          this.showSnackbar('Error al generar el certificado', 'is-danger')
        })
    },
    sendCert () {
      this.$http
        .get(`data:application/octet-stream;charset=utf-8;base64,${this.p12Base64}`, { baseURL: '', responseType: 'blob' })
        .then(resBlob => {
          const data = new FormData()
          data.append('archivo', new File([resBlob.data], 'cert.p12', { type: 'application/x-pkcs12' }))
          return this.$http
            .post(`/usuario/${this.user.id}/keystore`, data)
        })
        .then(() => {
          this.isLoading = false
          this.finish()
        })
        .catch(() => {
          this.isLoading = false
          this.showSnackbar('Error al guardar el certificado', 'is-danger')
        })
    },
    finish () {
      this.showSnackbar('Certificado generado exitosamente', 'is-success')
      this.goBack()
    },
    cancelCert () {
      this.$buefy.dialog.confirm({
        title: 'Cancelar solicitud',
        message: "<p class='is-centered'>La acción de cancelar la solicitud de certificado es <b>irreversible</b>. Si desea obtener un certificado tendrá que volver a realizar el proceso de solicitud.</p>",
        confirmText: 'Confirmar',
        cancelText: 'Volver',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true
          this.$http
            .post(`/certificado/${this.idCert}/revocar`, { razon: 'solicitud-cancelada' })
            .then(() => {
              this.showSnackbar('Solicitud cancelada exitosamente', 'is-success')
            })
            .catch(() => {
              this.showSnackbar('Error al cancelar la solicitud.', 'is-danger')
            })
            .finally(() => {
              this.isLoading = false
              this.goBack()
            })
        },
        onCancel: () => {}
      })
    },
    goBack () {
      this.$router.go(-1)
    },
    noError () {
      this.type = ''
      this.errorMessage = ''
    },
    hasError () {
      this.type = 'is-danger'
      this.errorMessage = 'Contraseña incorrecta'
    },
    showAlert (title, message) {
      this.$buefy.dialog.alert({
        title: title,
        message: message,
        type: 'is-danger',
        ariaModal: true
      })
    },
    showSnackbar (message, type) {
      this.$buefy.snackbar.open({
        message: message,
        type: type
      })
    }
  },
  computed: {
    user: function () {
      return this.$store.getters.getUser
    }
  }
}
</script>
